import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import EnumsContext from '../../../../context/enums-context';
import { formatCurrency } from '../../../../Utils/Utils';
import useAuthenticatedRequest from '../../../../Utils/TokenrRefreshUtils';
import useGetEnums from '../../../../Utils/EnumsUtils';

import commonStyle from "../../CommonStyles.module.css";

const Products = () => {

    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
    });

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const toast = useRef(null);

    useGetEnums(["product_groups"]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
    },[location.state]);

    useEffect(() => {

        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "products/products/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            url = onlyActive ? url + "&is_active=" + onlyActive : url;
            url = lazyState.sortField == null ? url : url + "&ordering=" + (lazyState.sortOrder === 1 ? "-" + lazyState.sortField : lazyState.sortField);
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            //logging to remove later
            console.log(parsedResponse);

            setTotalRecords(parsedResponse.count);
            setProducts(parsedResponse.results);
            setLoading(false);
        }

        fetchData();

    }, [lazyState, reloadData, authenticatedRequest, onlyActive]);

    const ChangeProductGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const callApi = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "products/products/" + action + "/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Товар деактивовано' : "Товар активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        callApi();
    }

    const DeleteButtonHandler = (rowData) => {

        const deleteData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "products/products/" + rowData.id + "/delete/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу товарів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const EditProductHandler = rowData => {
        const route = '/products/editproduct/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення товару',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const changeStatus = (event, rowData) => {
        console.log("Here!");
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації товару' : 'Підтвердження активації товару',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {ChangeProductGroupStatusHandler(rowData)}
        });
    }

    const tableHeader = (
        <div className={commonStyle.headerContainer}>
            <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
            <span>Лише активні</span>
        </div>
    );

    const images = require.context('../../../../images', true);

    const getPicture = pictureName => {
        const path = './' + pictureName;

        return images(path);
    }

    const StatusCircle = ({ rowData }) => {
        return (
            <div>
                {rowData.is_active ? (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.active}`}
                        data-pr-tooltip="Активний"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                ) : (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.inactive}`}
                        data-pr-tooltip="Деактивований"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                )}
                <Tooltip target={`#statusCircle-${rowData.id}`} />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => <StatusCircle rowData={rowData} />;

    const imageBodyTemplate = (product) => {
        return <img src={getPicture("Iphone14ProMax.jpg")} alt={product.image} className="w-8rem shadow-2 border-round" />;
    };

    const CreateProductHandler = () => {
        navigate('/products/createproduct');
    }

    const footer = `Загальна кількість товарів: ${totalRecords!= null ? totalRecords : 0}`;

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати товар" severity="success" className={commonStyle.addButton} onClick={CreateProductHandler} />
        </div>)
    }

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const rootGroupTemplate = (rowData) => {
        return ctx.product_groups[rowData.product_group];
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                onClick={(event) => changeStatus(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати"
                onClick={() => EditProductHandler(rowData)}
            />
        </div>
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-end'>
                <DataTable value={products} stripedRows dataKey="id" lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                first={lazyState.first} loading={loading} totalRecords={totalRecords} header={tableHeader} rowsPerPageOptions={[10, 20, 50]}
                 emptyMessage="Товарів не знайдено" footer={footer} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="logo" style={{ width: '5%' }} body={imageBodyTemplate}/>
                    <Column field="product_name" style={{ width: '13%' }} header="Назва товару" sortable />
                    <Column field="product_group" style={{ width: '13%' }} header="Назва групи" sortable  body={rootGroupTemplate}/>
                    <Column field="product_article" style={{ width: '10%' }} header="Артикул" sortable />                    
                    <Column field="price" style={{ width: '10%' }} header="Ціна" sortable body={rowData => formatCurrency(rowData.price, rowData.currency)}/>
                    <Column field="priceUAH" style={{ width: '10%' }} header="Ціна, UAH" sortable body={rowData => formatCurrency(rowData.priceUAH, "UAH")}/>
                    <Column field="created_at" style={{ width: '10%' }} header="Дата"dataType='date' sortable body={dateBodyTemplate}/>
                    <Column field="created_by" style={{ width: '10%' }} header="Користувач"/>
                    <Column field="is_active" style={{ width: '5%' }} header="Статус" body={statusBodyTemplate}/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
        </>)

};

export default Products;