import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";

import EnumsContext from "../../../../context/enums-context";
import { findKeyByValue } from "../../../../Utils/Utils";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import useGetEnums from "../../../../Utils/EnumsUtils";

import commonStyle from "../../CommonStyles.module.css";

const ServiceCreation = () => {

    const currencies = ["UAH", "USD", "EUR"];
    const statusList = ["Активний", "Деактивований"];

    const [customfields, setCustomFields] = useState(null);
    const [service, setService] = useState({
        name: '',
        service_group: null,
        service_article: '',
        price: '',
        currency: "UAH",
        notes: '',
        is_active: "Активний",
        service_fields_values: []
    })

    const navigate = useNavigate();
    const location = useLocation();

    const ctx = useContext(EnumsContext);
    useGetEnums(["service_groups"]);
    const authenticatedRequest = useAuthenticatedRequest();

    useEffect(() => {
        if (location.state !== null) {
            const fetchData = async () => {
                const url = process.env.REACT_APP_BACKEND_URL + "services/services/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                parsedResponse.service_group = ctx.service_groups[parsedResponse.service_group];
                parsedResponse.is_active = parsedResponse.is_active ? "Активний" : "Деактивований";

                let fields = [];
                let serviceFields = {};

                parsedResponse.service_fields_values.forEach(element => {
                    const field = {
                        id: element.service_group_field,
                        field_id: element.id,
                        name: element.name,
                        field_type: element.field_type
                    };

                    fields = [...fields, field];
                    serviceFields[element.service_group_field] = element.value;
                });

                parsedResponse.service_fields_values = serviceFields;

                console.log(parsedResponse);

                setService(parsedResponse);
                setCustomFields(fields);
            };

            fetchData();
        }
    }, [location.state, ctx.service_groups, authenticatedRequest]);

    const DropdownChangeHandler = async (e) => {
        const service_group = e.value;

        formik.setFieldValue('service_group', service_group);

        if (service_group != null) {

            const groupId = findKeyByValue(ctx.service_groups, service_group);

            const fetchData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "services/groups/" + groupId + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                };

                const response = await authenticatedRequest(url, options);

                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                const fields = parsedResponse.fields;

                setCustomFields(fields);

                console.log(fields);
                console.log(customfields);

                const transformedObject = fields.reduce((acc, field) => {
                    acc[field.id] = "";
                    return acc;
                }, {});

                formik.setFieldValue('service_fields_values', transformedObject);
            }

            fetchData();
        }
        else {
            setCustomFields(null);
        }
    };

    const formik = useFormik({
        initialValues: service,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.name === null || values.name === "") {
                errors.name = "Назва послуги не повинна бути пустою";
            }

            if (values.service_group === null || values.service_group === undefined) {
                errors.service_group = "Виберіть групу послуг";
            }

            if (values.price === null || values.price === "") {
                errors.price = "Введіть ціну послуги";
            }

            return errors;
        },
        onSubmit: (values) => {

            values.service_group = findKeyByValue(ctx.service_groups, values.service_group);

            values.is_active = values.is_active === "Активний" ? true : false;

            let fields = [];

            Object.entries(values.service_fields_values).forEach(([key, value]) => {
                const field = {
                    service_group_field: key,
                    value: value,
                }

                if (location.state != null) {
                    const element = customfields.find(element => element.id === Number(key));
                    field.id = element.field_id;
                }

                fields = [
                    ...fields,
                    field
                ]

            });

            values.service_fields_values = fields;
            
            console.log(values);

            const backEndUrl = location.state === null ? process.env.REACT_APP_BACKEND_URL + "services/services/create/" : process.env.REACT_APP_BACKEND_URL + "services/services/"+ location.state.id + "/update";
            const method = location.state === null ? "POST" : "PUT";

            const fetchData = async () => {

                const url = backEndUrl;
                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                //logging to remove later
                console.log(response);

                navigate("/services");
            }

            fetchData();
        }
    });

    const ReturnButtonHandler = () => {
        navigate("/services");
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення послуги</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        );
    }

    return (<>
        <form onSubmit={formik.handleSubmit}>
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
            <div className="grid nested-grid">
                <div className={`${commonStyle.leftMenu} col-3`}>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Dropdown
                                    value={formik.values.service_group}
                                    onChange={DropdownChangeHandler}
                                    options={Object.values(ctx.service_groups)}
                                    showClear
                                    className={formik.errors.service_group && formik.touched.service_group ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва групи</label>
                            </FloatLabel>
                            {formik.errors.service_group && formik.touched.service_group && (<small className={commonStyle.errorSmall}>{formik.errors.service_group}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText name="name" value={formik.values.name}
                                    onChange={(e) => {formik.setFieldValue('name', e.target.value);}} 
                                    className={formik.errors.name && formik.touched.name ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва послуги</label>
                            </FloatLabel>
                            {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="service_article"
                                    value={formik.values.service_article}
                                    onChange={(e) => { formik.setFieldValue('service_article', e.target.value) }}
                                />
                                <label>Артикул</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                <Dropdown className={`${commonStyle.currencyDropDown} w-full`} value={formik.values.currency} onChange={(e) => formik.setFieldValue('currency', e.value)} options={currencies} />
                            </span>
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={formik.values.price}
                                    onChange={(e) => { formik.setFieldValue('price', e.target.value) }}
                                    className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Ціна</label>
                            </FloatLabel>
                            {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <FloatLabel>
                                <Dropdown name="status" value={formik.values.is_active} onChange={(e) => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                <label>Статус</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
                <div className="col-9">
                    <TabView>
                        <TabPanel header="Додаткові поля">
                            <div className="grid">
                                {customfields != null && (customfields.map((field) => (
                                    <div className="col-4" key={field.id}>
                                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                            <FloatLabel>
                                                <InputText
                                                    name={`service_fields_values.${field.id}`}
                                                    value={formik.values.service_fields_values[field.id] || ""}
                                                    onChange={(e) => { formik.setFieldValue(`service_fields_values.${field.id}`, e.target.value) }}
                                                />
                                                <label>{field.name}</label>
                                            </FloatLabel>
                                        </div>
                                    </div>
                                )))}
                            </div>
                        </TabPanel>
                        <TabPanel header="Опис">  
                            <InputTextarea className={commonStyle.fullWidth} value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} />
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </form>
    </>
    );
}

export default ServiceCreation;