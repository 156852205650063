import { useCallback } from 'react';
import useAuthenticatedRequest from './TokenrRefreshUtils';

const useApi = () => {
    const authenticatedRequest = useAuthenticatedRequest();

    const handleApiCall = useCallback(async (url, options) => {
        const response = await authenticatedRequest(url, options);
        const clientResponse = {};
        clientResponse.status = response.status;
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            try{
                clientResponse.data = await response.json();
            }
            catch(error){
                console.log("Error parsing response", error)
            }
        }
        return clientResponse;
    }, [authenticatedRequest]);

    //accounting_payments controller
    const fetchPayments = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_list/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchPaymentTransactions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_payment_distributed_transactions/?payment_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //accounting_transactions controller
    const fetchTransactions = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_list/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchTransactionPayments = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_distributed_payments/?transaction_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //auth controller
    const createUser = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/create_user/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const fetchUserData = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/users/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserPermissions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_permissions/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserRoles = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/get_list/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const updateUser = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/update_user/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //cagents controller
    const deleteClient = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchClients = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //client_return_acts controller
    const fetchClientReturnActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const deleteClientReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    }, [handleApiCall]);

    //currency controller
    //dashboards controller
    const fetchCostsWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/costs_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductCountWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_products_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);
    
    const fetchRevenueWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/revenue_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServicesGivenWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_services_widget`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    //divisions controller
    //organiztion controller
    //products controller
    const changeProductGroupStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const deleteProductGroup = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/delete/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchProductGroups = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}products/groups/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    //receive_acts controller
    //remainings controller
    //roles controller
    //sale_acts controller
    //services controller

    return {
        changeProductGroupStatus,
        createUser,
        deleteClient,
        fetchCostsWidget,
        deleteClientReturnAct,
        deleteProductGroup,
        fetchClientReturnActs,
        fetchClients,
        fetchPayments,
        fetchPaymentTransactions,
        fetchProductCountWidget,
        fetchProductGroups,
        fetchRevenueWidget,
        fetchServicesGivenWidget,
        fetchTransactionPayments,
        fetchTransactions,
        fetchUserData,
        fetchUserPermissions,
        fetchUserRoles,
        updateUser
    };
};

export default useApi;