import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import useGetEnums from '../../../../Utils/EnumsUtils';
import EnumsContext from '../../../../context/enums-context';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';

import './ProductGroupCreation.css';
import commonStyle from '../../CommonStyles.module.css';
import { FloatLabel } from 'primereact/floatlabel';
import useAuthenticatedRequest from '../../../../Utils/TokenrRefreshUtils';

const ProductGroupsCreation = () => {

    const fieldTypes = ["STRING", "INTEGER", "FLOAT", "DATE"];
    const statusList = ["Активний", "Деактивований"];

    const [selectedField, setSelectedField] = useState(null);
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const [isEdit, setIsEdit] = useState(false);
    const [group, setGroup] = useState({
        group_name: '',
        root_group: null,
        accounting_type: 'PIECE',
        identifier_name: '',
        description: '',
        is_active: null,
        fields: [],
    });

    const navigate = useNavigate();
    const location = useLocation();

    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();

    // Getting enums
    useGetEnums(["product_groups"]);

    // Condition in case of adding and editing
    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "products/groups/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                parsedResponse.is_active = parsedResponse.is_active ? "Активний" : "Деактивований";
                parsedResponse.root_group = ctx.product_groups[parsedResponse.root_group];

                setGroup(parsedResponse);

            }

            fetchData();
        }
    }, [location.state, ctx.product_groups, authenticatedRequest]);

    //Main form for editing
    const formik = useFormik({
        initialValues: group,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.group_name === null || values.group_name === "") {
                errors.group_name = "Назва групи не повинна бути пустою";
            }

            if (values.accounting_type === 'INDIVIDUAL' && values.identifier_name === '') {
                errors.identifier_name = "Поле для ідентифікації не повинне бути пустим для індивідуального обліку";
            }

            return errors;
        },
        onSubmit: (values) => {

            values.root_group = Object.keys(ctx.product_groups).find(key => ctx.product_groups[key] === values.root_group);

            if (group.is_active !== values.is_active) {
                ctx.product_groups = [];
                console.log("cleaned productGroups");
            }

            if (values.is_active === "Активний") {
                values.is_active = true;
            }
            else {
                values.is_active = false;
            }

            console.log(values);

            const saveChanges = async () => {

                const url = isEdit ? process.env.REACT_APP_BACKEND_URL + "products/groups/" + values.id + "/update/" : process.env.REACT_APP_BACKEND_URL + "products/groups/create/";
                const options = {
                    method: isEdit ? "PUT" : "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                //logging to remove later
                console.log(response);

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Групу не " + (isEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
                };

                if (isEdit && response.status === 200) {
                    toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Групу " + values.group_name + " успішно відредаговано", life: 3000 }
                }
                else if (!isEdit && response.status === 201) {
                    toastMessage = { severity: "success", summary: "Успіх створення", detail: "Групу " + values.group_name + " успішно створено", life: 3000 }
                }

                navigate("/productgroups", { state: { toast: toastMessage } });
            }

            saveChanges();
        }
    });

    //Dialog form
    const dialogFormik = useFormik({
        initialValues: {
            field_name: '',
            field_type: null,
            mandatory: false
        },
        validate: values => {
            const errors = {};

            if (values.field_name === null || values.field_name === "") {
                errors.field_name = "Назва поля не повинна бути пустою";
            }

            if (values.field_type === null || values.field_type === '') {
                errors.field_type = "Виберіть тип поля";
            }

            return errors;
        },
        onSubmit: values => {
            console.log(values);

            if (formik.values.fields.length === 0) {
                formik.setFieldValue('fields', [values]);
            }
            else {
                formik.setFieldValue('fields', [...formik.values.fields, values])
            }
            dialogFormik.resetForm();
            setVisible(false);
        }
    });

    const ReturnButtonHandler = () => {
        navigate('/productgroups');
    }

    const show = (position) => {
        setPosition(position);
        setVisible(true);
    };

    const ObligatoryBodyTemplate = (product) => {

        if (product.mandatory) {
            return <Tag className={commonStyle.successTag} value='Так' severity="success" style={{ width: '20%' }} />;
        }

        return <Tag className={commonStyle.deletedTag} value="Ні" severity="danger" style={{ width: '20%' }}></Tag>;
    };

    const DeleteFieldHandler = (fieldName) => {

        const updatedFields = [...formik.values.fields];

        const index = formik.values.fields.findIndex(function (field) {
            return field.field_name === fieldName
        });

        updatedFields.splice(index, 1);
        formik.setFieldValue('fields', updatedFields);
    };

    const rightToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap justify-content-end">
                <Button label="Додати" icon="pi pi-plus" type="button" className={commonStyle.addButton} onClick={() => show('top')} severity="success" />
            </div>
        );
    };

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення групи товарів</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" onClick={ReturnButtonHandler} className={commonStyle.closeButton} />
            </div>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                onClick={() => DeleteFieldHandler(rowData.field_name)}
            />
        </div>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid">
            <div className="leftMenu col-3">
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="group_name"
                                value={formik.values.group_name}
                                onChange={(e) => { formik.setFieldValue('group_name', e.target.value) }}
                                className={formik.errors.group_name && formik.touched.group_name ? 'p-invalid' : ''}
                            />
                            <label>Назва групи</label>
                        </FloatLabel>
                        {formik.errors.group_name && formik.touched.group_name && (<small className={commonStyle.errorSmall}>{formik.errors.group_name}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex flex-column">
                        <FloatLabel>
                            <Dropdown name="root_group" value={formik.values.root_group} onChange={(e) => formik.setFieldValue('root_group', e.value)} options={Object.values(ctx.product_groups)} showClear className="w-full" />
                            <label>Батьківська група</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Dropdown name="status" value={formik.values.is_active} onChange={(e) => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                            <label>Статус</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className="radioButtonBlock">
                    <div className="accountingRadio">
                        <RadioButton value="INDIVIDUAL" checked={formik.values.accounting_type === "INDIVIDUAL"} onChange={(e) => { formik.setFieldValue('accounting_type', "INDIVIDUAL"); }} />
                        <label className="ml-2">Індивідуальний облік</label>
                    </div>
                    <div className="accountingRadio">
                        <RadioButton value="PIECE" checked={formik.values.accounting_type === "PIECE"} onChange={(e) => { formik.setFieldValue('accounting_type', "PIECE"); }} />
                        <label className="ml-2">Поштучний облік</label>
                    </div>
                </div>
                {formik.values.accounting_type === "INDIVIDUAL" && (<div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex flex-column gap-1">
                            <FloatLabel>
                                <InputText
                                    name="idField"
                                    value={formik.values.identifier_name}
                                    onChange={(e) => { formik.setFieldValue('identifier_name', e.target.value) }}
                                    className={formik.errors.identifier_name && formik.touched.identifier_name ? 'p-invalid' : ''}
                                />
                                <label >Поле для ідентифікації</label>
                            </FloatLabel>
                            {formik.errors.identifier_name && formik.touched.identifier_name && (<small className={commonStyle.errorSmall}>{formik.errors.identifier_name}</small>)}
                        </div>
                    </div>
                </div>)}
            </div>
            <div className="col-9">
                <TabView>
                    <TabPanel  className="p-0" header="Налаштування полів">
                        <DataTable className="tableStyle" header={rightToolbarTemplate} value={formik.values.fields} selection={selectedField} onSelectionChange={(e) => setSelectedField(e.value)} rows={10} tableStyle={{ minWidth: '60rem' }} >
                            <Column field="field_name" header="Назва поля" sortable></Column>
                            <Column field="field_type" header="Тип поля" sortable></Column>
                            <Column field="mandatory" dataType="bool" align="center" body={ObligatoryBodyTemplate} header="Обов'язковість" ></Column>
                            <Column field="action" body={actionBodyTemplate} />
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Опис">
                        <div className="tableStyle"><InputTextarea value={formik.values.description} onChange={(e) => { formik.setFieldValue('description', e.target.value) }} rows={10} className={commonStyle.fullWidth}></InputTextarea></div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
        <Dialog header="Додати поле до послуги"
            visible={visible}
            position={position}
            style={{ width: '30vw', display: 'flex', flexDirection: 'column' }}
            onHide={() => setVisible(false)}
            draggable={false}
            resizable={false}
        >
            <form onSubmit={dialogFormik.handleSubmit} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                <div className="dialogFormInput">
                    <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                        <FloatLabel>
                            <InputText
                                name="field_name"
                                value={dialogFormik.values.field_name}
                                onChange={(e) => { dialogFormik.setFieldValue('field_name', e.target.value) }}
                                className={dialogFormik.errors.field_name && dialogFormik.touched.field_name ? 'p-invalid' : ''}
                            />
                            <label>Назва поля</label>
                        </FloatLabel>
                        {dialogFormik.errors.field_name && dialogFormik.touched.field_name && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.field_name}</small>)}
                    </div>
                </div>
                <div className="dialogFormInput">
                    <div className="p-inputgroup flex flex-column gap-1 md:w-22rem">
                        <FloatLabel>
                            <Dropdown
                                name="field_type"
                                value={dialogFormik.values.field_type}
                                onChange={(e) => dialogFormik.setFieldValue('field_type', e.value)}
                                options={fieldTypes}
                                showClear
                                className={dialogFormik.errors.field_type && dialogFormik.touched.field_type ? 'p-invalid w-full' : 'w-full'}
                            />
                            <label>Тип поля</label>
                        </FloatLabel>
                        {dialogFormik.errors.field_type && dialogFormik.touched.field_type && (<small className={commonStyle.errorSmall}>{dialogFormik.errors.field_type}</small>)}
                    </div>
                </div>
                <div className="dialogFormInput">
                    <div className="checkboxStyle p-inputgroup flex-1 md:w-22rem">
                        <Checkbox
                            name="mandatory"
                            onChange={(e) => { dialogFormik.setFieldValue('mandatory', !dialogFormik.values.mandatory) }}
                            checked={dialogFormik.values.mandatory}
                        />
                        <label>Обовязковість</label>
                    </div>
                </div>
                <div className="dialogSubmit">
                    <Button label="Додати поле" icon="pi pi-check" type="submit" className={commonStyle.addButton} severity="success" autoFocus />
                </div>
            </form>
        </Dialog>
    </form>);
};

export default ProductGroupsCreation;