import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApi from '../../../Utils/BackendClient';
import useGetEnums from '../../../Utils/EnumsUtils';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import commonStyle from '../CommonStyles.module.css';
import EnumsContext from '../../../context/enums-context';
import { UserPermissionContext } from '../../../context/UserPermissionProvider';

const ProductGroups = () => {
    const navigate = useNavigate();
    const location = useLocation();
    useGetEnums(["product_groups"]);
    const ctx = useContext(EnumsContext);
    const toast = useRef(null);
    const { changeProductGroupStatus, deleteProductGroup, fetchProductGroups } = useApi();

    const [reloadData, setReloadData] = useState(false);
    const [productGroups, setProductGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const {permissions} = useContext(UserPermissionContext);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
    });

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
    },[location.state]);

    useEffect(() => {

        const callBackend = async () => {

            const response = await fetchProductGroups(onlyActive, lazyState);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setProductGroups([]);
            }
            else
            {
                setTotalRecords(response.data.count);
                setProductGroups(response.data.results);
            }

            setLoading(false);
        }

        callBackend();

    }, [reloadData, lazyState, fetchProductGroups, onlyActive]);

    const ChangeProductGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const changeStatus = async () => {

            const response = await changeProductGroupStatus(rowData.id, action);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Групу товарів деактивовано' : "Групу товарів активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        changeStatus();
    }

    const DeleteButtonHandler = (rowData) => {

        const deleteData = async () => {

            const response = await deleteProductGroup(rowData.id);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу товарів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const CreateGroupButtonHandler = () => {
        navigate('/productgroups/createproductgroup');
    }

    const EditButtonHandler = (rowData) => {
        const route = '/productgroups/editproductgroup/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const changeStatus = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації групи' : 'Підтвердження активації групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {ChangeProductGroupStatusHandler(rowData)}
        });
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
        console.log(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const tableHeader = (
        <div className={commonStyle.headerContainer}>
            <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
            <span>Лише активні</span>
        </div>
    );

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати групу" severity="success" className={commonStyle.addButton} onClick={CreateGroupButtonHandler} />
        </div>)
    }

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const StatusCircle = ({ rowData }) => {
        return (
            <div>
                {rowData.is_active ? (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.active}`}
                        data-pr-tooltip="Активний"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                ) : (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.inactive}`}
                        data-pr-tooltip="Деактивований"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                )}
                <Tooltip target={`#statusCircle-${rowData.id}`} />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => <StatusCircle rowData={rowData} />;

    const actionBodyTemplate = (rowData) => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button
                    icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                    className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                    tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                    onClick={(event) => changeStatus(event, rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                    tooltip={rowData.can_be_deleted ? "Видалити групу" : "Неможливо видалити, приєднані товари"}
                    tooltipOptions={{ showOnDisabled: true }}
                    disabled={!rowData.can_be_deleted}
                    onClick={(event) => confirmDelete(event, rowData)}
                />
                <Button
                    icon="pi pi-pencil"
                    className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                    tooltip="Редагувати групу"
                    onClick={() => EditButtonHandler(rowData)}
                />
            </div>
        </>);
    };

    const rootGroupTemplate = (rowData) => {
        return ctx.product_groups[rowData.root_group];
    }

    console.log(permissions);

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={productGroups} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 20]} header={tableHeader} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="group_name" header="Назва групи" sortable></Column>
                    <Column field="root_group" header="Батьківська група" body={rootGroupTemplate} sortable></Column>
                    <Column field="created_at" dataType="date" header="Дата" body={dateBodyTemplate} sortable></Column>
                    <Column field="created_by" header="Користувач"></Column>
                    <Column field="is_active" header="Статус" sortable body={statusBodyTemplate} />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>);
}

export default ProductGroups;