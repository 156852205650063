import { useEffect, useState } from "react";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import commonStyle from "../../CommonStyles.module.css";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";

const Organization = () => {

    const [organizationDetails, setOrganizationDetails] = useState({
        logo_url: "",
        name:"",
        email:"",
        phone:"",
        contact_person:""
    });
    const authenticatedRequest = useAuthenticatedRequest();

    useEffect(() => {

        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "organization/get_details/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            //logging to remove later
            console.log(parsedResponse);

            setOrganizationDetails(parsedResponse);
        }

        fetchData();

    }, [authenticatedRequest]);

    return (<div className={commonStyle.orgContent}>
        <div className="grid">
            <div className="col-3">
                <div className={commonStyle.portraitdiv}>
                    <img className={commonStyle.portrait} alt={organizationDetails?.name} src={organizationDetails?.logo_url} style={{ borderRadius: "50%" }} />
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.name}
                        />
                        <label>Назва організації</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.email}
                        />
                        <label>E-mail</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.phone}
                        />
                        <label>Телефон</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={organizationDetails?.contact_person}
                        />
                        <label>Відповідальна особа</label>
                    </FloatLabel>
                </div>
            </div>
        </div>
    </div>)
}

export default Organization;