import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import EnumsContext from "../../../../context/enums-context";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { Toolbar } from "primereact/toolbar";

import './ProductCreation.module.css';
import commonStyle from "../../CommonStyles.module.css";
import { findKeyByValue } from "../../../../Utils/Utils";


const ProductCreation = () => {
    const currencies = ["UAH", "USD", "EUR"];
    const statusList = ["Активний", "Деактивований"];

    const [customfields, setCustomFields] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [product, setProduct] = useState({
        product_name: "",
        product_group: null,
        product_article: "",
        currency: "UAH",
        price: "",
        is_active: "Активний",
        product_fields_values: {}
    });

    const navigate = useNavigate();
    const location = useLocation();

    const ctx = useContext(EnumsContext);
    useGetEnums(["product_groups"]);
    const authenticatedRequest = useAuthenticatedRequest();

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);
            const fetchData = async () => {
                const url = process.env.REACT_APP_BACKEND_URL + "products/products/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                parsedResponse.product_group = ctx.product_groups[parsedResponse.product_group];
                parsedResponse.is_active = parsedResponse.is_active ? "Активний" : "Деактивований";

                let fields = [];
                let productFields = {};

                parsedResponse.product_fields_values.forEach(element => {
                    const field = {
                        id: element.product_group_field,
                        field_id: element.id,
                        field_name: element.field_name,
                        field_type: element.field_type
                    };

                    fields = [...fields, field];
                    productFields[element.product_group_field] = element.value;
                });

                console.log(fields);

                parsedResponse.product_fields_values = productFields;

                console.log(parsedResponse);

                setProduct(parsedResponse);
                setCustomFields(fields);
            };

            fetchData();
        }
    }, [location.state, ctx.product_groups, authenticatedRequest]);

    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (!values.product_name) {
                errors.product_name = "Назва продукту не повинна бути пустою";
            }

            if (!values.product_group) {
                errors.product_group = "Назва групи не повинна бути пустою";
            }

            if (!values.price) {
                errors.price = "Введіть ціну товару";
            }

            return errors;
        },
        onSubmit: values => {
            values.product_group = findKeyByValue(ctx.product_groups, values.product_group);
            values.is_active = values.is_active === "Активний";

            let fields = [];

            Object.entries(values.product_fields_values).forEach(([key, value]) => {
                const field = {
                    product_group_field: key,
                    value: value,
                }

                if (location.state != null) {
                    const element = customfields.find(element => element.id === Number(key));
                    field.id = element.field_id;
                }

                fields = [
                    ...fields,
                    field
                ]

            });

            values.product_fields_values = fields;

            console.log(values);

            const backEndUrl = location.state === null ? process.env.REACT_APP_BACKEND_URL + "products/products/create/" : process.env.REACT_APP_BACKEND_URL + "products/products/" + location.state.id + "/update/";
            const method = location.state === null ? "POST" : "PUT";

            const fetchData = async () => {
                const url = backEndUrl;
                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                //logging to remove later
                console.log(response);

                let toastMessage = {
                    severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення",
                    detail: "Товар не " + (isEdit ? "відредаговано. " : " створено. ") + "Помилка сервера " + response.status, life: 3000
                };

                if (isEdit && response.status === 200) {
                    toastMessage = { severity: "success", summary: "Успіх редагування", detail: "Товар " + values.product_name + " успішно відредаговано", life: 3000 }
                }
                else if (!isEdit && response.status === 201) {
                    toastMessage = { severity: "success", summary: "Успіх створення", detail: "Товар " + values.product_name + " успішно створено", life: 3000 }
                }

                navigate("/products", { state: { toast: toastMessage } });
            };

            fetchData();
        }
    });

    const DropdownChangeHandler = async e => {
        const product_group = e.value;

        formik.setFieldValue('product_group', product_group);

        if (product_group) {
            const groupId = findKeyByValue(ctx.product_groups, product_group);

            const fetchData = async () => {
                const url = process.env.REACT_APP_BACKEND_URL + "products/groups/" + groupId + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                const fields = parsedResponse.fields;

                setCustomFields(fields);

                const transformedObject = fields.reduce((acc, field) => {
                    acc[field.id] = "";
                    return acc;
                }, {});

                formik.setFieldValue('product_fields_values', transformedObject);
            };

            fetchData();
        } else {
            setCustomFields([]);
        }
    };

    const SaveButtonHandler = () => {
        navigate('/products');
    };

    const Upload = () => { };

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення товару</h3>);
    };

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} type="button" onClick={SaveButtonHandler} />
            </div>
        );
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
            <div className="grid nested-grid">
                <div className="leftMenu col-3">
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Dropdown
                                    value={formik.values.product_group}
                                    onChange={DropdownChangeHandler}
                                    options={Object.values(ctx.product_groups)}
                                    showClear
                                    className={formik.errors.product_group && formik.touched.product_group ? 'p-invalid w-full' : 'w-full'} />
                                <label>Назва групи</label>
                            </FloatLabel>
                            {formik.errors.product_group && formik.touched.product_group && (<small className={commonStyle.errorSmall}>{formik.errors.product_group}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_name"
                                    value={formik.values.product_name}
                                    onChange={e => formik.setFieldValue('product_name', e.target.value)}
                                    className={formik.errors.product_name && formik.touched.product_name ? 'p-invalid' : ''}
                                />
                                <label>Назва товару</label>
                            </FloatLabel>
                            {formik.errors.product_name && formik.touched.product_name && (<small className={commonStyle.errorSmall}>{formik.errors.product_name}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="product_article"
                                    value={formik.values.product_article}
                                    onChange={e => formik.setFieldValue('product_article', e.target.value)}
                                />
                                <label>Артикул</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className={`${commonStyle.formInput}`}>
                        <div className="p-inputgroup py-0 flex-1">
                            <span className="currencySpan p-inputgroup-addon">
                                <Dropdown 
                                className="currencyDropDown" 
                                value={formik.values.currency} 
                                onChange={e => formik.setFieldValue('currency', e.value)} 
                                options={currencies} />
                            </span>
                            <FloatLabel>
                                <InputText
                                    name="price"
                                    value={formik.values.price}
                                    onChange={e => formik.setFieldValue('price', e.target.value)}
                                    className={formik.errors.price && formik.touched.price ? 'p-invalid' : ''}
                                />
                                <label>Ціна</label>
                            </FloatLabel>
                            {formik.errors.price && formik.touched.price && (<small className={commonStyle.errorSmall}>{formik.errors.price}</small>)}
                        </div>
                    </div>
                    <div className={commonStyle.formInput}>
                        <div className="p-inputgroup flex-1">
                            <FloatLabel>
                                <Dropdown name="status" value={formik.values.is_active} onChange={e => formik.setFieldValue('is_active', e.value)} options={statusList} showClear className="w-full" />
                                <label>Статус</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div>
                        <label>Завантажити фото</label>
                        <FileUpload
                            mode="basic"
                            name="demo[]"
                            accept="image/*"
                            maxFileSize={1000000}
                            customUpload
                            uploadHandler={Upload}
                        >
                        </FileUpload>
                    </div>
                </div>
                <div className="col-9">
                    <TabView>
                        <TabPanel header="Налаштування полів">
                            <div className="grid">
                                {customfields.length > 0 && (customfields.map((field) => (
                                    <div className="col-4" key={field.id}>
                                        <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                                            <FloatLabel>
                                                <InputText
                                                    name={`product_fields_values.${field.id}`}
                                                    value={formik.values.product_fields_values[field.id] || ""}
                                                    onChange={e => formik.setFieldValue(`product_fields_values.${field.id}`, e.target.value)}
                                                />
                                                <label>{field.field_name}</label>
                                            </FloatLabel>
                                        </div>
                                    </div>
                                )))}
                            </div>
                        </TabPanel>
                        <TabPanel header="Опис">
                            <div className="tableStyle">
                                <InputTextarea name="description" className={commonStyle.fullWidth} rows={10}/>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </form>
    );
}

export default ProductCreation;