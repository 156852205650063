import EnumsContext from "../../../../context/enums-context";
import { formatCurrency, formatDate } from "../../../../Utils/Utils";
import StatusCircle from "../../../../Utils/StatusCircle";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const ProductsReturn = () => {

    const { fetchClientReturnActs, deleteClientReturnAct } = useApi();
    const navigate = useNavigate();
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    useGetEnums(["divisions", "clients", "pay_statuses"]);

    const [productReturnActs, setProductReturnActs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1
    });

    useEffect(() => {

        const getClientsReturns = async () => {
            const response = await fetchClientReturnActs(lazyState);
            console.log(response);

            if(response.status === 200){
                setTotalRecords(response.data.count);
                setProductReturnActs(response.data.results);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Користувач не має права переглядати сторінку", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Помилкв сервера: " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        getClientsReturns();

    },[lazyState, reloadData, fetchClientReturnActs]);

    const CreateNewProductsReturnButtonHandler = () => {
        navigate("/productsreturn/newproductsreturn");
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
        console.log(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const DeleteButtonHandler = (rowData) => {
        const deleteAct = async () => {

            const response = await deleteClientReturnAct(rowData.id);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-lock"
                className={`p-button-rounded p-button-success ${rowData.status !== "FINALIZED" ? commonStyle.addButton : commonStyle.closeButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Фіналізувати" : "Закритий"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.status === "FINALIZED"}
                onClick={(event) => console.log(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon={rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye"}
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту"}
                tooltipOptions={{ position: "top" }}
                onClick={() => console.log(rowData)}
            />
        </div>
        );
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт повернення товару" severity="success" className={commonStyle.addButton} onClick={CreateNewProductsReturnButtonHandler} />
        </div>)
    }

    return (<>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={productReturnActs} stripedRows lazy rows={lazyState.rows} onPage={onPage} loading={loading} first={lazyState.first} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                emptyMessage="Актів повернення не знайдено" paginator totalRecords={totalRecords} rowsPerPageOptions={[10, 20]}>
                    <Column field="act_number" header="Номер акту" sortable  style={{ width: '7%' }}/>
                    <Column field="act_date" dataType="date" header="Дата акту" />
                    <Column field="division" header="Підрозділ" body={rowData => ctx.divisions[rowData.division]} sortable />
                    <Column field="cagent" header="Клієнт" body={rowData => ctx.clients[rowData.cagent]} sortable />
                    <Column field="amountUAH" header="Сума, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} sortable />
                    <Column field="pay_status" header="Оплачено" body={rowData => ctx.pay_statuses[rowData.pay_status]} />
                    <Column field="status" header="Статус" body={rowData => <StatusCircle rowData={rowData}/>} sortable style={{ width: '4%' }} />
                    <Column field="created_by" header="Користувач" />
                    <Column field="created_at" dataType="date" header="Дата" body={rowData => formatDate(rowData.created_at)} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>);
}

export default ProductsReturn;