import Cookies from "js-cookie";

export const findKeyByValue = (array, value) => {

    if(!Array.isArray(array)){
        array = [array];
    }

    for (const member of array) {
        const entry = Object.entries(member).find(([key, val]) => val === value);
        if (entry) {
            return entry[0];
        }
    }
    return null; // Return null if the value is not found
};

export const convertSumWithLatestCurrencyRate = (currency, value) => {
    if(currency === "USD"){
        return value * Cookies.get("USDRate");
    }
    else if(currency === "EUR"){
        return value * Cookies.get("EURRate");
    }
    else{
        return value;
    }
}

export const formatCurrency = (value, currency) => {
    const locale = currency === "UAH" ? "ua-UA" : "en-EN";

    return value?.toLocaleString(locale, { style: 'currency', currency: currency });
};

export const formatShortDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

export const formatDate = (unformattedDate) => {
    const date = new Date(unformattedDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}