import EnumsContext from "../../../../context/enums-context";
import StatusCircle from "../../../../Utils/StatusCircle";
import { formatCurrency, formatShortDate } from "../../../../Utils/Utils";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";


const Sales = () => {

    const currencies = ["EUR", "USD", "UAH"];
    const paymentMethods = ["Термінал", "Готівка", "IBAN"];

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [sales, setSales] = useState(null);
    const [reloadData, setReloadData] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedSale, setSelectedSale] = useState(null);
    const [unDividedSum, setUnDividedSum] = useState(0);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1
    });

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const toast = useRef(null);

    useGetEnums(["divisions", "clients", "pay_statuses"]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }

        location.state = null;
    },[location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "sale_acts/get_list/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            url = lazyState.sortField == null ? url : url + "&ordering=" + (lazyState.sortOrder === 1 ? "-" + lazyState.sortField : lazyState.sortField);
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response !== null && response.status === 200) {
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);
                setTotalRecords(parsedResponse.count);
                setSales(parsedResponse.results);
            }
            else {
                setSales([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [lazyState, authenticatedRequest, reloadData]);

    // Payments
    const formik = useFormik({
        initialValues: {
            products: [],
            discount: [],
            basePayments: [],
            payments: [],
            client: null
        },
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            return errors
        },
        onSubmit: values => {

            let paymentRequest = [];

            values.payments.forEach(payment => {

               let paymentType = "CASH";
               if (payment.paymentMethod === "Термінал") {
                  paymentType = "CARD";
               }
               else if (payment.paymentMethod === "IBAN") {
                  paymentType = "IBAN";
               }

               const singlePaymentRequest = {
                  act_id: selectedSale.id,
                  date: formatShortDate(Date.now()),
                  sum: payment.sum,
                  currency: payment.currency,
                  type: paymentType,
                  method: "INCOME",
                  cagent_id: values.client != null ? values.client.id : null,
               }

               paymentRequest.push(singlePaymentRequest);
            });

            const createPayment = async () => {

                const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
                const options = {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(paymentRequest)
                };
                const paymentResponse = await authenticatedRequest(url, options);

                if (paymentResponse.status === 201) {
                    toast.current.show({severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000});
                    setReloadData();
                    setVisible(false);
                }
                else {
                    toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + paymentResponse.status, life: 3000 });
                }
            }

            createPayment();
        }
    });

    useEffect(() => {
        let convertedAmount = 0;
  
        if (formik.values.payments.length !== 0) {
           formik.values.payments.forEach(payment => {
              convertedAmount += payment.sum * (payment.currency === "UAH" ? 1 : Cookies.get(`${payment.currency}Rate`));
           });
        }
        
        setUnDividedSum(totalSumUAH - convertedAmount);
  
    }, [formik.values.payments, formik.values.discount, totalSumUAH]);

    const calculateTotals = (products) => {
        const totals = {};
  
        products.forEach(product => {
           const quantity = product.quantity ?? 1;
           const saleAmount = product.price * quantity;
           const currency = product.currency;
  
           if (!totals[currency]) {
              totals[currency] = {
                 currency: currency,
                 sum: 0,
                 paymentMethod: null // Or any default value if needed
              };
           }
  
           totals[currency].sum += saleAmount;
        });
  
        return Object.values(totals);
    };

    const PaymenButtonHandler = (rowData) => {

        const fetchActDetails = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_details/${rowData.id}/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();
            const modifiedSale = {};
   
            modifiedSale.division = parsedResponse.division;
            modifiedSale.act_date = parsedResponse.act_date;
            modifiedSale.act_number = parsedResponse.act_number;
            modifiedSale.pay_due_date = parsedResponse.pay_due_date;
            modifiedSale.id = rowData.id;
            modifiedSale.products = [];
            modifiedSale.discount = parsedResponse.discount;
   
            if (parsedResponse.identified_products.length > 0) {
               modifiedSale.products = [
                  ...modifiedSale.products,
                  ...parsedResponse.identified_products
               ];
            }
   
            if (parsedResponse.unidentified_products.length > 0) {
               modifiedSale.products = [
                  ...modifiedSale.products,
                  ...parsedResponse.unidentified_products
               ];
            }
   
            if (parsedResponse.services.length > 0) {
               modifiedSale.products = [
                  ...modifiedSale.products,
                  ...parsedResponse.services
               ];
            }
   
            const totals = calculateTotals(modifiedSale.products);
   
            if (modifiedSale.discount !== null && modifiedSale.discount.length > 0) {
               totals.forEach(total => {
                  const object = modifiedSale.discount.find(d => d.currency === total.currency);
   
                  if(object !== undefined){
                     total.sum -= object.amount;
                  }
               });
            }
   
            formik.setFieldValue('products', modifiedSale.products);
            formik.setFieldValue('basePayments', totals);
            formik.setFieldValue('payments', JSON.parse(JSON.stringify(totals)));
            formik.setFieldValue('discount', modifiedSale.discount);
   
            let amountUAH = 0;
            totals.forEach(total => {
               amountUAH += total.sum * (total.currency === "UAH" ? 1 : Cookies.get(`${total.currency}Rate`));
            });
   
            setTotalSumUAH(amountUAH);
   
            setSelectedSale(modifiedSale);

            setVisible(true);
        }

        fetchActDetails();
    }

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        console.log(index);
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "sale_acts/make_finalized/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = (rowData) => {

        const deleteAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "sale_acts/delete/" + rowData.id + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const addPaymentToFormik = () => {
        const newPayment = [
           ...formik.values.payments,
           { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const getDiscountValue = () => {

        let message = "";
  
        if(formik.values.discount !== null && formik.values.discount.length > 0)
        {
           message = "Знижка: "
           formik.values.discount.forEach(discount => {
              const value = formatCurrency(discount.amount, discount.currency);
              message += (value + " ");
           });
        }
        else{
           message = "Без знижки"
        }
  
        return message;
    };

    const CreateNewSaleButtonHandler = () => {
        navigate('/sales/newsale');
    };

    const EditSaleHandler = rowData => {
        const route = '/sales/editsale/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть фіналізацію акту. Ви не будете мати можливості редагувати акт',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    }  
    //End payments

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати замовлення" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const isPaymentAvailable = (rowData) => {
        if(rowData.pay_status === "NOT_PAYED"){
            if(rowData.status === "FINALIZED"){
                return true;
            }
        }

        return false;
    }

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-lock"
                className={`p-button-rounded p-button-success ${rowData.status !== "FINALIZED" ? commonStyle.addButton : commonStyle.closeButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Фіналізувати" : "Закритий"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.status === "FINALIZED"}
                onClick={(event) => confirmFinalize(event, rowData)}
            />
            <Button
                icon="pi pi-money-bill"
                className={`p-button-rounded p-button-warning ${isPaymentAvailable(rowData) ? commonStyle.warningTag : commonStyle.closeButton}`}
                tooltip="Оплатити"
                tooltipOptions={{ position: "top" }}
                disabled={!isPaymentAvailable(rowData)}
                onClick={() => PaymenButtonHandler(rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon={rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye"}
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту"}
                tooltipOptions={{ position: "top" }}
                onClick={() => EditSaleHandler(rowData)}
            />
        </div>
        );
    };

    return (
        <>
            <Toast ref={toast} />
            <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
            <div className='grid'>
                <div className='col-12 justify-content-center'>
                    <DataTable value={sales} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} emptyMessage="Товарів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                        <Column field="act_number" header="Номер акту" sortable />
                        <Column field="act_date" dataType="date" header="Дата акту" />
                        <Column field="cagent" header="Контрагент" body={rowData => ctx.clients[rowData.cagent]} sortable />
                        <Column field="division" header="Підрозділ" body={rowData => ctx.divisions[rowData.division]} sortable />
                        <Column field="amountUAH" header="Сума, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} sortable />
                        <Column field="pay_status" header="Статус оплати" body={rowData => ctx.pay_statuses[rowData.pay_status]} />
                        <Column field="pay_due_date" header="Дата оплати" />
                        <Column field="status" header="Статус" style={{ width: '4%' }} body={rowData => <StatusCircle rowData={rowData}/>} />
                        <Column field="created_by" header="Менеджер" sortable />
                        <Column field="created_at" header="Дата" body={dateBodyTemplate} sortable />
                        <Column field="action" body={actionBodyTemplate} />
                    </DataTable>
                </div>
                <ConfirmPopup group="headless" />
            </div>
            <Sidebar className="responsive-sidebar" header="Оплата товару" visible={visible} position="right" onHide={() => setVisible(false)}>
                <form onSubmit={formik.handleSubmit}>
                    {formik.values.payments.length === 0 && (
                        <div className="mb-2">
                            <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати" />
                        </div>
                    )}
                    {formik.values.payments.map((currencyAmount, index) => (
                        <div className="grid py-3" key={index}>
                            <div className="col-10 p-inputgroup py-0 flex-1">
                                <span className={`${commonStyle.currencySpan} p-inputgroup-addon`}>
                                    <Dropdown
                                        className={commonStyle.currencyDropDown}
                                        value={currencyAmount.currency}
                                        onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                        options={currencies.map(currency => ({ label: currency, value: currency }))}
                                    />
                                </span>
                                <InputText
                                    name={`currencyValuePair[${index}].amount`}
                                    value={currencyAmount.sum}
                                    onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                                />
                            </div>
                            <div className="col-2">
                                <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                            </div>
                            <div className="col-10 py-0">
                                <Dropdown
                                    className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? `${commonStyle.fullWidth} p-invalid` : `${commonStyle.fullWidth}`}
                                    value={currencyAmount.paymentMethod}
                                    onChange={e => handlePaymentMethodChange(index, e.value)}
                                    options={paymentMethods.map(method => ({ label: method, value: method }))}
                                    placeholder="Спосіб оплати"
                                />
                                {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                                    <small className={commonStyle.errorSmall}>{formik.errors.payments[index].paymentMethod}</small>
                                )}
                            </div>
                        </div>
                    ))}
                    <div className='mb-2'>
                        <Message className={commonStyle.fullWidth} severity={"info"} text={`${getDiscountValue()}`} />
                        <Message className={commonStyle.fullWidth} severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
                    </div>
                    <div>
                        <Button label="+ Додати валюту" severity="info" type="button" className={`${commonStyle.fullWidth} ${commonStyle.editButton}`} onClick={addPaymentToFormik} />
                        {formik.values.payments.length !== 0 && <Button label="Оплатити" severity="success" type="submit" className={`${commonStyle.fullWidth} ${commonStyle.addButton} mt-1`} />}
                    </div>
                </form>
            </Sidebar>
        </>)
};

export default Sales;