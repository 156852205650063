import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";

import commonStyle from "../../CommonStyles.module.css";
import useGetEnums from "../../../../Utils/EnumsUtils";
import EnumsContext from "../../../../context/enums-context";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { Toast } from "primereact/toast";

const ServiceGroups = () => {

    const [serviceGroups, setServiceGroups] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(true);
    const [onlyActive, setOnlyActive] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1
    });

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    useGetEnums(["service_groups"]);
    const ctx = useContext(EnumsContext);

    const authenticatedRequest = useAuthenticatedRequest();

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
    },[location.state]);

    useEffect(() => {

        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "services/groups/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            url = onlyActive ? url + "&is_active=" + onlyActive : url;
            url = lazyState.sortField == null ? url : url + "&ordering=" + (lazyState.sortOrder === 1 ? "-" + lazyState.sortField : lazyState.sortField);
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            const parsedResponse = await response.json();
            //logging to remove later
            console.log(parsedResponse);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setServiceGroups([]);
            }
            else {
                setTotalRecords(parsedResponse.count);
                setServiceGroups(parsedResponse.results);
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, reloadData, authenticatedRequest, onlyActive]);

    const ChangeServiceGroupStatusHandler = (rowData) => {

        const action = rowData.is_active ? "deactivate" : "activate";

        const changeStatus = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "services/groups/" + action + "/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка зміни статусу", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: rowData.is_active ? 'Деактивація' : "Активація", detail: rowData.is_active ? 'Групу сервісів деактивовано' : "Групу сервісів активовано", life: 3000 });
            }

            setReloadData(!reloadData);
        }

        changeStatus();
    }

    const DeleteServiceGroupHandler = (rowData) => {

        const deleteData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "services/groups/" + rowData.id + "/delete/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Групу сервісів успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteData();
    }

    const CreateServiceGroupHandler = () => {
        navigate('/servicegroups/createservicegroup');
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердження видалення групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {DeleteServiceGroupHandler(rowData)}
        });
    }

    const changeStatus = (event, rowData) => {
        console.log("Here!");
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: rowData.is_active ? 'Підтвердження деактивації групи' : 'Підтвердження активації групи',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            accept: () => {ChangeServiceGroupStatusHandler(rowData)}
        });
    }

    const EditButtonHandler = (rowData) => {
        const route = '/servicegroups/editgroup/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const OnlyActiveHandler = (event) => {
        setOnlyActive(event);
    }

    const tableHeader = (
        <div className={commonStyle.headerContainer}>
            <InputSwitch checked={onlyActive} onChange={(e) => OnlyActiveHandler(e.value)} />
            <span>Лише активні</span>
        </div>
    );

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати групу послуг" severity="success" className={commonStyle.addButton} onClick={CreateServiceGroupHandler} />
        </div>)
    }

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const StatusCircle = ({ rowData }) => {
        return (
            <div>
                {rowData.is_active ? (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.active}`}
                        data-pr-tooltip="Активний"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                ) : (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.inactive}`}
                        data-pr-tooltip="Деактивований"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                )}
                <Tooltip target={`#statusCircle-${rowData.id}`} />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => <StatusCircle rowData={rowData} />;

    const actionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                onClick={(event) => changeStatus(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити групу" : "Неможливо видалити, приєднані товари"}
                tooltipOptions={{ showOnDisabled: true }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip="Редагувати групу"
                onClick={() => EditButtonHandler(rowData)}
            />
        </div>
        );
    };

    const rootGroupTemplate = (rowData) => {
        return ctx.service_groups[rowData.root_group];
    }

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={serviceGroups} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[5, 10, 20]} header={tableHeader} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="name" header="Назва групи" sortable></Column>
                    <Column field="root_group" header="Батьківська група" body={rootGroupTemplate} sortable></Column>
                    <Column field="created_at" dataType="date" header="Дата" body={dateBodyTemplate} sortable ></Column>
                    <Column field="created_by" header="Користувач" sortable></Column>
                    <Column field="is_active" header="Статус" sortable body={statusBodyTemplate} />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>
    );
}

export default ServiceGroups;