import { useState, useEffect } from 'react';

import Cookies from 'js-cookie';
import Authorization from "./components/Authorization/Authorization";
import Layout from './components/Layout';

import './App.css';
import UserDetailsProvider from './context/UserDetailsProvider';
import EnumsProvider from './context/EnumsProvider';
import { UserPermissionProvider } from './context/UserPermissionProvider';


function App() {
  const [isLoginned, setIsLoggined] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (Cookies.get('authName')!== undefined) {
        setIsLoggined(true);
        console.log(Cookies.get('authName'))
      }
      setLoading(false);
    };

    checkLoginStatus();
  }, []);

  const LoginUserHandler = (args) => {
    Cookies.set('authToken', args.accessToken);
    Cookies.set('refreshToken', args.refreshToken);
    Cookies.set('authName', args.userName);
    Cookies.set('avatar',args.avatar);
    setIsLoggined(true);
  };

  const LoggoffHandler = args => {
    setIsLoggined(false);
  }

  if (loading) {
    // Loading state while checking login status
    return <div>Loading...</div>;
  }

  return (
    <UserPermissionProvider>
      <UserDetailsProvider>
        <EnumsProvider>
          {isLoginned ? (
            <Layout onLogoff={LoggoffHandler} />
          ) : (
            <Authorization onLoginProcess={LoginUserHandler} />
          )}
        </EnumsProvider>
      </UserDetailsProvider>
    </UserPermissionProvider>
  );
}

export default App;
